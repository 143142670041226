export default [
  {
    name: '401',
    path: '/401',
    component: () => import('./views/auth/401.vue'),
    meta: {
      skipAuthChecks: true
    }
  },
  {
    name: 'index',
    path: '',
    component: () => import('./views/index.vue'),
    meta: { roles: ['admin'] }
  },
  {
    name: 'collection-schemas',
    path: '/schemas',
    component: () => import('./views/schemas/schema-list.vue'),
    meta: { roles: ['admin'] }
  },
  {
    name: 'collection-schema',
    path: '/schemas/:collectionId',
    props: true,
    component: () => import('./views/schemas/schema.vue'),
    meta: { roles: ['admin'] }
  },
  {
    name: 'collection',
    path: '/collection/:collectionId',
    props: true,
    component: () => import('./views/collections/collection.vue'),
    meta: { roles: ['admin'] }
  },
  {
    name: 'hierarchy',
    path: '/hierarchy/:collectionId',
    props: true,
    component: () => import('./views/hierarchies/hierarchy.vue'),
    meta: { roles: ['admin'] }
  },
  {
    name: 'collection-item',
    path: '/collection/:collectionId/:itemKey+',
    props: true,
    component: () => import('./views/collection-item/collection-item.vue'),
  },
  {
    path: '/appearance/collections',
    props: true,
    component: () => import('./views/appearance/collections/collections.vue'),
    meta: {
      roles: ['admin'],
    }
  },
  {
    path: '/appearance/relations',
    props: true,
    component: () => import('./views/appearance/relations/relations.vue'),
    meta: {
      roles: ['admin'],
    }
  },
  {
    path: '/appearance/reverseRelations',
    props: true,
    component: () => import('./views/appearance/reverse-relations/reverse-relations.vue'),
    meta: {
      roles: ['admin'],
    }
  },
  {
    name: 'relation',
    path: '/relation/:relationId/:sourceKey',
    props: true,
    component: () => import('./views/relations/relation-editor.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    name: 'reverse-relation',
    path: '/reverse-relation/:relationId/:targetKey',
    props: true,
    component: () => import('./views/relations/reverse-relation-editor.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/suite/:suiteId',
    props: true,
    component: () => import('./views/suites/suite.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/suite-schemas',
    props: true,
    component: () => import('./views/suite-schemas/suite-schema-list.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/suite-schemas/create-new',
    props: true,
    component: () => import('./views/suite-schemas/create-suite-schema.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/suite-schema/:suiteId',
    props: true,
    component: () => import('./views/suite-schemas/edit-suite-schema.vue'),
    meta: {
      roles: ['admin']
    }
  },
  {
    path: '/users',
    props: true,
    component: () => import('./views/users/users.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/apikeys',
    name: 'api-keys',
    props: true,
    component: () => import('./views/apikeys/apikeys.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/apikeys/create-new',
    props: true,
    component: () => import('./views/apikeys/create-api-key.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/apikey/:apiKey',
    name: 'edit-api-key',
    props: true,
    component: () => import('./views/apikeys/edit-api-key.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/channels',
    component: () => import('./views/channels/channels.vue'),
    meta: { roles: ['admin'] }
  },
  /*****************************
    *
    * Stored queries
    *
    ***************************/
  {
    path: '/stored-queries',
    name: 'stored-queries',
    component: () => import('./views/stored-queries/stored-queries.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/stored-queries/create-new',
    name: 'create-stored-query',
    props: true,
    component: () => import('./views/stored-queries/create-stored-query.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '/stored-queries/:storedQueryId',
    name: 'edit-stored-query',
    props: true,
    component: () => import('./views/stored-queries/edit-stored-query.vue'),
    meta: { roles: ['admin'] }
  },


  /*****************************
    *
    * Dashboard
    *
    ***************************/
  {
    name: 'dashboard-action-collection',
    path: '/dashboard/collection/:actionId/:actionResultKey',
    props: true,
    component: () => import('./views/dashboard/dashboard-action-collection.vue'),
    meta: { roles: ['admin'] }
  },


  {
    path: '/graphql-report-builder',
    component: () => import('./views/graphql-report-builder/graphql-report-builder.vue'),
    meta: { roles: ['admin'] }
  },

  {
    path: '/client-error-log',
    component: () => import('./views/client-error-log/client-error-log.vue'),
    meta: { roles: ['admin'] }
  },

  {
    path: '/',
    component: () => import('./views/index.vue'),
    meta: { roles: ['admin'] }
  },
  {
    path: '*',
    name: 'not-found',
    component: () => import('./views/not-found.vue')
  }
]
