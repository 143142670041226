import sortBy from 'lodash/sortBy'

/**
  * Given a list, annotate each item with an order property
  */
const applyOrder = list => list.map((item, index) => ({...item, order: index + 1}))

/**
  *
  * Sort items [{order: number}] by order
  * or by [{[key]: ...}] if order is not a number
  */
const conventionSort = (list, key) => {
  let sorted = list.filter(({order}) => (typeof order === 'number'))
  let unsorted = list.filter(({order}) => (typeof order !== 'number'))

  return sortBy(sorted, item => item.order + 1).concat(sortBy(unsorted, key))
    .map((item, index) => ({
      ...item,
      order: index + 1
    }))
}

/**
  * Given a list, build map/object from calculated key and calculated value
  *
  */
const toMap = (list, keyFn, valueFn) => list.reduce((memo, item) => {
  memo[keyFn(item)] = valueFn(item)
  return memo
}, {})

/**
  * Parse JSON and reurn undefined on any exception
  *
  */
const safeJsonParse = text => {
  try {
    return text && JSON.parse(text)
  } catch (err) {
    return undefined
  }
}


export {
  applyOrder,
  conventionSort,
  safeJsonParse,
  toMap
}
