import Vue from 'vue'
import {
  VLayout,
  VProgressLinear,
  VDataTable,
  VIcon,
  VBtn,
//  VListTileIcon
} from 'vuetify/lib'


Vue.component('v-layout', VLayout)
Vue.component('v-progress-linear', VProgressLinear)
Vue.component('v-data-table', VDataTable)
Vue.component('v-icon', VIcon)
Vue.component('v-btn', VBtn)
//Vue.component('v-list-tile-icon', VListTileIcon)
