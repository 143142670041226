<template>
  <v-snackbar
    v-model="snackbar"
    :multi-line="true"
    :color="color"
    :timeout="timeout">
    {{ message }}
    <v-btn
      dark
      text
      @click="snackbar = false"
    >
      Close
    </v-btn>
  </v-snackbar>

</template>
<script>
import {mapGetters} from 'vuex'
const messageDefaults = {
  'error': {
    message: 'An error occured',
    timeout: 10000,
    color: 'error'
  },
  '*': {
    message: 'An error occured',
    timeout: 3000,
    color: 'secondary'
  }
}

export default {
  data: () => ({
    snackbar: false,
    message: '',
    timeout: 3000,
    color: 'error',
    queue: []
  }),
  mounted () {
    this.notifications.on('notification-message', message => this.enqueue(message))
  },
  watch: {
    snackbar (val) {
      if (!val) {
        // snackbar is closing for some reason
        // show remaining messages
        this.$nextTick(() => this.tryDequeue())
      }
    }
  },
  computed: {
    ...mapGetters(['notifications'])
  },
  methods: {
    enqueue (message) {
      this.queue.push(message)
      this.tryDequeue()
    },
    tryDequeue () {
      if (this.snackbar) {
        // snackbar is open
        return
      }
      let message = this.queue.shift()
      if (message) {
        let m = {
          ...(messageDefaults[message.type] || messageDefaults['*']),
          ...message
        }
        this.message = m.message
        this.color = m.color
        this.timeout = m.timeout
        this.snackbar = true
      }
    }
  }
}
</script>
