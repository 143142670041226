import Vue from 'vue'

import vuetify from './plugins/vuetify'
import './plugins/vue-axios'
import './plugins/chartkick'
import './plugins/vue-async-computed'
import './plugins/components'
import './plugins/tiptap-vuetify'

import App from './App.vue'
import store from './store'
import router from './router'
import i18n from './i18n'

import {mapActions} from 'vuex'

Vue.config.productionTip = false
Vue.config.errorHandler = function (err, vm, info) {
  // We simulate the the faulting component (vm), has mapped the action logClientError
  // and then we invoke it
  let logClientError = mapActions(['logClientError']).logClientError.bind(vm)
  logClientError({err, info})
/*
  // eslint-disable-next-line no-console
  console.error({
    err,
    vm,
    info
  })
*/  
}

new Vue({
  vuetify,
  store,
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
