import get from 'object-get'
import {conventionSort, toMap} from './utilities'

export default class ReverseRelationsConfig {
  constructor (clientConfiguration) {
    this.config = clientConfiguration
  }

  mapClientSettings (relationSchemas) {
    const g = path => get(this.config, path)
    const normalizeSchema = schema => ({
      relationId: schema.relationId,
      sourceCollectionId: schema.sourceCollectionId,
      targetCollectionId: schema.targetCollectionId,
      label: g(`reverseRelations.${schema.relationId}.label`) || null,
      order: g(`reverseRelations.${schema.relationId}.order`) || null
    })
    return {
      clientSettingsId: get(this.config, 'clientSettingsId') || '',
      // make collection/schemas a list instead of map
      reverseRelations: conventionSort(
        Object.values(relationSchemas)
        .map(schema => normalizeSchema(schema)),
        'collectionId')
    }
  }
  normalizeClientSettings (clientSettings) {
    return {
      ...clientSettings,
      reverseRelations: toMap(
        clientSettings.reverseRelations,
        ({relationId}) => relationId,
        ({label, order}) => ({
          label,
          order
        }))
    }
  }

  getReverseRelationLabel (relationId) {
    return get(this.config, `reverseRelations.${relationId}.label`) || relationId
  }

  mapReverseRelations (relations) {
    const g = path => get(this.config, path)
    let mapped = relations.map(relation => ({
        relationId: relation.relationId,
        relation,
        label: g(`reverseRelations.${relation.relationId}.label`) || relation.relationId,
        order: g(`reverseRelations.${relation.relationId}.order`) || null
      }))
    return conventionSort(mapped, 'relationId')
  }

  sortReverseRelations (relations) {
    const g = path => get(this.config, path)
    return conventionSort(
      relations.map(relation => ({
        relation,
        relationId: relation.relationId,
        order: g(`reverseRelations.${relation.relationId}.order`) || null
      })),
      ({relationId}) => relationId)
      .map(({relation}) => relation)
  }
}
