import ChildApi from './child-api'

class AuthenticationApi extends ChildApi {
  async getEndpoints ({cached = false, query} = {}) {
    return this.post({
      url: '/api/v1/authenticate/endpoints',
      cached,
      query
    })
  }
}

export default AuthenticationApi
