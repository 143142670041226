import EventEmitter from 'events'

export default {
  state: {
    notifications: new EventEmitter(),
  },
  mutations: {
    'NOTIFICATION-MESSAGE': (state, notification) => {
      state.notifications.emit('notification-message', notification)
    }
  },
  getters: {
    notifications: state => state.notifications,
  },
  actions: {
    initializeStore (/*{state, commit}*/) {
    }
  }
}
