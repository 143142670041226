export default {
  state: {
  },
  mutations: {
  },
  getters: {
  },
  actions: {
    initializeStore (/*{state, dispatch}*/) {
    },
    initializeAuthenticatedStore(/*{dispatch}*/) {
    }
  }
}
