<template>
  <div class="d-flex">
    <v-spacer />
    <v-btn text :href="endpoint.url">
      <img :src="image" />
    </v-btn>
  </div>
</template>
<script>
import googleImage from '@/assets/google_signin_buttons/web/1x/btn_google_signin_light_normal_web.png'

export default {
  props: ['endpoint'],
  computed: {
    image() { return googleImage }
  }
}
</script>
