<template>
  <v-app id="inspire">
    <v-main>
      <v-container
        class="fill-height"
        fluid
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            sm="8"
            md="4"
          >
            <v-skeleton-loader
              class="mx-auto elevation-12" v-if="loading"
              max-width="300"
              type="card" />

            <v-card class="elevation-12" v-if="!loading">
              <v-toolbar
                color="primary"
                dark
                flat
              >
                <v-spacer />
                <v-toolbar-title>
                  {{translate('Welcome to PicoPIM', 'login.welcome')}}
                </v-toolbar-title>
                <v-spacer />
              </v-toolbar>

              <v-card-text>
                <v-list>
                  <v-list-item v-for="c in signinComponents" :key="c.key">
                    <v-list-item-content>
                      <component :key="c.key" :is="c.component" :endpoint="c.endpoint" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import {mapGetters} from 'vuex'
import parseUrl from 'url-parse'

import GoogleOauth20Endpoint from './google-oauth20-endpoint.vue'
import LocalEndpoint from './local-endpoint.vue'

const EndpointComponents = {
  'google-oauth20': GoogleOauth20Endpoint,
  'basic': LocalEndpoint
}

export default {
  props: {
    source: String
  },
  computed: {
    ...mapGetters(['api', 'translate']),
    loading () { return this.details.loading },
    signinComponents () {
      return this.details.endpoints
        .filter(v => v)
        .map(endpoint => ({
          key: endpoint.endpointId,
          endpoint,
          component: EndpointComponents[endpoint.type]
        }))
        .filter(({component}) => component)
    }
  },
  asyncComputed: {
    details: {
      async get () {
        let fail = parseUrl(this.returnUrl || '/', true)
        fail.query['pp_auth_failed'] = '1'
        let endpoints = await this.api.authentication.getEndpoints({
          query: {
            successUrl: this.returnUrl,
            failUrl: fail.toString()
          }
        })
        return {
          loading: false,
          endpoints
        }
      },
      default: {
        loading: true,
        endpoints: []
      }
    }
  }
}
</script>
