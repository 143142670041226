<template>
  <div id="app">
    <v-app id="inspire">
      <app-drawer :expanded="drawer" />
      <app-bar v-model="drawer" />
      <v-main>
        <v-container fluid fill-height>
          <router-view :key="$route.fullPath"/>
        </v-container>
        <app-snackbar />
      </v-main>
    </v-app>
  </div>
</template>
<script>

import AppBar from './layout/app-bar.vue'
import AppDrawer from './layout/app-drawer.vue'
import AppSnackbar from './layout/app-snackbar.vue'
export default {
  data: () => ({
    drawer: true
  }),
  components: {
    AppBar,
    AppDrawer,
    AppSnackbar
  }
}
</script>
