import axios from 'axios'

export default {
  state: {
    attributeTypes: []
  },
  mutations: {
    'SET_ATTRIBUTE_TYPES': (state, attributeTypes) => {
      state.attributeTypes = attributeTypes
    }
  },
  getters: {
    attributeTypes: state => state.attributeTypes
  },
  actions: {
    initializeStore (/*{state, dispatch}*/) {
    },
    initializeAuthenticatedStore({dispatch}) {
      return dispatch('requestAttributeTypes')
    },
    requestAttributeTypes ({commit}) {
      return axios.get('/api/v1/types')
        .then(({data}) => commit('SET_ATTRIBUTE_TYPES', data))
    }
  }
}
