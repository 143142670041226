const LS_KEY = 'client-errors'
// const MAX_AGE = /* millis */ 1000 * 60 * 60 * 24 * 7
const MAX_ENTRIES = 16

const nop = () => 0
const makeArray = a => a && Array.isArray(a) ? a : []
const lsguard = fn => {
  try {
    let ls = window && window.localStorage && (typeof window.localStorage.getItem === 'function') && (typeof window.localStorage.setItem === 'function') && window.localStorage
    return ls && fn(ls)
  } catch (e) {
    nop()
  }
}

const cleanEntries = a => a
    .filter(v => v)
    // .filter(({time}) => time + MAX_AGE > Date.now())
    .slice(0, MAX_ENTRIES)

const getLogEntries = () => makeArray(lsguard(ls => JSON.parse(ls.getItem(LS_KEY))))
const setLogEntries = entries => lsguard(ls => ls.setItem(LS_KEY, JSON.stringify(entries)))

export default {
  state: {
    clientErrors: []
  },
  getters: {
    clientErrors: state => state.clientErrors
  },
  actions: {
    initializeStore({commit}) {
      commit('initializeLogStore')
    },
    logClientError({commit}, {err, info}) {
      if (err) {
        commit('logClientError', {err, info})
      }
    },
    clearClientErrors({commit}) {
      commit('clearClientErrors')
    }
  },
  mutations: {
    initializeLogStore (state) {
      state.clientErrors = getLogEntries()
    },
    logClientError(state, {err, info}) {
      let entry = {
        time: new Date(),
        error: err,
        info
      }
      setLogEntries(cleanEntries([entry, ...state.clientErrors]))
      state.clientErrors = getLogEntries()
    },
    clearClientErrors (state) {
      setLogEntries([])
      state.clientErrors = getLogEntries()
    }
  }
}
