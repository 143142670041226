import ChildApi from './child-api'

class ChannelsApi extends ChildApi {
  async getChannel ({channelId, cached = false, query} = {}) {
    return this.get({
      url: `/api/v1/channels/${channelId}`,
      query,
      cached
    })
  }
  async getChannels ({cached = true, query} = {}) {
    return this.get({
      url: '/api/v1/channels',
      query,
      cached
    })
  }
  createChannel ({update}) { return this.updateChannel({update, query: {'create-only': 1}}) }
  updateChannel ({update, query}) {
    return this.post({
      url: '/api/v1/channels/update',
      body: update,
      query
    })
  }
  deleteChannel ({channelId}) {
    return this.post({
      url: `/api/v1/channels/delete/${channelId}`
    })
  }
}

export default ChannelsApi
