<template>
  <v-icon>{{value ? 'far fa-check-circle' : 'far fa-circle'}}</v-icon>
  <!--<v-icon v-if="value">fa-check</v-icon>-->
  <!--<v-icon>{{value ? 'check_circle_outline' : 'circle_outline'}}</v-icon>-->
  <!--
  <v-checkbox
    disabled
    v-model="value" />
  -->

</template>
<script>
import createPropertyAccessor from '@/lib/create-property-accessor'
export default {
  props: ['header', 'item'],
  data: () => ({accessor: createPropertyAccessor()}),
  computed: {
    value () { return this.accessor(this.item, this.header.value) }
  }
}
</script>
