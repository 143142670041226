import get from 'object-get'
import handlebars from 'handlebars'
import {applyOrder, conventionSort, toMap, safeJsonParse} from './utilities'

import ItemTableCellTemplateValue from '@/components/item-tables/item-table-cell-template-value.vue'
import ItemTableCellValue from '@/components/item-tables/item-table-cell-value.vue'
import ItemTableCellLinkValue from '@/components/item-tables/item-table-cell-link-value.vue'

const compileTemplate = template => {
  const t = template && template.trim()
  return t ? handlebars.compile(t) : null
}

export default class CollectionsConfig {
  constructor (clientConfiguration) {
    this.config = clientConfiguration
  }

  mapClientSettings (collectionSchemas) {
    const g = path => get(this.config, path)

    const normalizeAttribute = ({collectionId}, {name}) => ({
      name,
      label: g(`collections.${collectionId}.attributes.${name}.label`) || null,
      order: g(`collections.${collectionId}.attributes.${name}.order`) || null,
      list: {
        show: g(`collections.${collectionId}.attributes.${name}.list.show`) !== false,
        load: g(`collections.${collectionId}.attributes.${name}.list.load`) !== false,
      }
    })

    const normalizeSchema = schema => ({
      collectionId: schema.collectionId,
      label: g(`collections.${schema.collectionId}.label`) || null,
      order: g(`collections.${schema.collectionId}.order`) || null,
      templates: {
        list: g(`collections.${schema.collectionId}.templates.list`) || null,
        form: g(`collections.${schema.collectionId}.templates.form`) || null
      },
      layout: {
        form: g(`collections.${schema.collectionId}.layout.form`) || null
      },
      // make attributes list from map
      attributes: conventionSort(
        Object.values(schema.attributes).map(attribute => normalizeAttribute(schema, attribute)),
        'name')
    })

    // return toMap(conventionSort(Object.values(collectionSchemas), 'collectionId'), ({collectionId}) => collectionId, schema => normalizeSchema(schema))

    return {
      clientSettingsId: get(this.config, 'clientSettingsId') || '',
      // make colelction/schemas a list instead of map
      collections: conventionSort(
        Object.values(collectionSchemas)
        .map(schema => normalizeSchema(schema)),
        'collectionId')
    }
  }

  normalizeClientSettings (clientSettings) {
    return {
      ...clientSettings,
      collections: toMap(applyOrder(clientSettings.collections), schema => schema.collectionId, schema => ({
        ...schema,
        attributes: toMap(applyOrder(schema.attributes), a => a.name, a => a)
      }))
    }
  }

  mapCollectionSchemas (collectionSchemas) {
    const g = path => get(this.config, path)

    return conventionSort(Object.values(collectionSchemas || {})
      .map(schema => ({
        collectionId: schema.collectionId,
        schema,
        order: g(`collections.${schema.collectionId}.order`) || null
      })), 'collectionId')
      .map(({collectionId, schema}) => ({
        collectionId,
        schema,
        label: g(`collections.${schema.collectionId}.label`) || collectionId,
        attributes: conventionSort(
          Object.values(schema.attributes || [])
            .map(attribute => ({
              attribute,
              name: attribute.name,
              label: g(`collections.${collectionId}.attributes.${attribute.name}.label`),
              order: g(`collections.${collectionId}.attributes.${attribute.name}.order`)
            })), 'name')
      }))
  }

  getCollectionLabel (collectionId) {
    return collectionId && get(this.config, `collections.${collectionId}.label`) || collectionId
  }

  getCollectionAttributeLabel (collectionId, name) {
    return collectionId && name && get(this.config, `collections.${collectionId}.attributes.${name}.label`) || name
  }

  getCollectionItemTemplate (collectionId) {
    return collectionId && compileTemplate(get(this.config, `collections.${collectionId}.templates.form`))
  }
  getCollectionTableItemTemplate ({collectionId}) {
    return collectionId && compileTemplate(get(this.config, `collections.${collectionId}.templates.list`))
  }

  getTableAttributesForLoading (collectionId) {
    const g = path => get(this.config, path)

    const attributes = Object.values(g(`collections.${collectionId}.attributes`) || {})
      .map(({name}) => ({
        name,
        show: g(`collections.${collectionId}.attributes.${name}.list.show`) === true,
        load: g(`collections.${collectionId}.attributes.${name}.list.load`) === true
      }))
      .filter(({show, load}) => show || load)
      .map(({name}) => name)
    return attributes.length ? attributes : null
  }

  /**
    * Return Vuetify v-data-table headers
    * based on schema and current user config of visible attributes
    *
    * Each header contains a display name (mix of attribute and config)
    * and a value, which is a data path (on the form 'item.[attr]')
    *
    * The dot-notation in data paths are nescessary for
    * - datatables built in search to function
    *
    * Also, item data results from server a quite hierarchical, like
    * {items: [{item: {attributes}, completeness: {...}, ...}]}
    *
    */
  getTableHeaders (schema) {
    // TODO: this function is obselete and should be refactored out
    const g = path => get(this.config, path)

    const makeComponent = inner => ({
      props: ['header', 'item'],
      render: function (h) {
        return h(inner, {
          props: {
            header: this.header,
            item: this.item
          }
        })
      }
    })

    const getAttributeComponent = ({type}) => type === 'image'
      ? ItemTableCellLinkValue
      : type === 'url'
      ? ItemTableCellLinkValue
      : ItemTableCellValue

    const {collectionId} = schema

    const attributeHeaders = conventionSort(
      Object.values(schema.attributes || [])
        .filter(({name}) => g(`collections.${collectionId}.attributes.${name}.list.show`) !== false)
        .map(attribute => ({
          attribute,
          name: attribute.name,
          order: g(`collections.${collectionId}.attributes.${attribute.name}.order`)
        })), 'name')
      .map(({attribute, name}) => ({
        attribute,
        text: g(`collections.${collectionId}.attributes.${name}.label`) || name,
        value: `item.${name}`,
        sortable: true,
        type: {
          attribute: true,
          schema: true
        },
        component: getAttributeComponent(attribute)
      }))

    let template = compileTemplate(g(`collections.${collectionId}.templates.list`))
    const templateHeaders = template
      ? [{
        text: '',
        value: 'item',
        sortable: false,
        template,
        component: makeComponent(ItemTableCellTemplateValue),
        type: {
          template: true,
          schema: true
        }
      }]
      : []

    return templateHeaders.concat(attributeHeaders)
  }

  mapCollectionAttributes({collectionId, attributes}) {
    const g = path => get(this.config, path)
    return conventionSort(
      Object.values(attributes || [])
        .map(attribute => ({
          attribute,
          name: attribute.name,
          order: g(`collections.${collectionId}.attributes.${attribute.name}.order`)
        })), 'name')
      .map(({attribute, name}) => ({
        name,
        attribute,
        label: g(`collections.${collectionId}.attributes.${name}.label`) || name
      }))
  }

  mapCollectionTableAttributes (schema) {
    const g = path => get(this.config, path)
    const {collectionId} = schema
    return conventionSort(
      Object.values(schema.attributes || [])
        .filter(({name}) => g(`collections.${collectionId}.attributes.${name}.list.show`) !== false)
        .map(attribute => ({
          attribute,
          name: attribute.name,
          order: g(`collections.${collectionId}.attributes.${attribute.name}.order`)
        })), 'name')
      .map(({attribute, name}) => ({
        name,
        attribute,
        label: g(`collections.${collectionId}.attributes.${name}.label`) || name
      }))
  }

  mapFormLayout (collectionSchema) {
    const g = path => get(this.config, path)
    const {collectionId, key} = collectionSchema
    let layout = safeJsonParse(g(`collections.${collectionId}.layout.form`)) || []

    const isKeyAttribute = ({name}) => name === key

    const usedAttributes = new Set()
    const designerGroups = Object.values(layout)
      .map(({label, rows}) => ({
        label,
        rows: rows.map(items => items
          //.filter(({name}) => designerAttributes[name] = name)
          .map(({name, w, ro}) => ({
            label: g(`collections.${collectionId}.attributes.${name}.label`) || name,
            w,
            ro,
            attribute: collectionSchema.attributes[name]
          }))
          .filter(({attribute}) => attribute)
          .filter(({attribute: {name}}) => usedAttributes.add(name) || true)
          .map(l => ({
            ...l,
            ro: l.ro || isKeyAttribute(l.attribute)
          })))
        .filter(row => row.length)
      }))

    const defaultGroup = {
      // label: g(`collections.${collectionId}.label`) || name,
      label: '*',
      rows: Object.values(collectionSchema.attributes)
        .filter(({name}) => !usedAttributes.has(name))
        .map(attribute => ([{
          label: g(`collections.${collectionId}.attributes.${attribute.name}.label`) || attribute.name,
          w: 1,
          ro: isKeyAttribute(attribute),
          attribute
        }]))
    }

    return designerGroups.concat([defaultGroup])
      .filter(({rows}) => rows.length)
  }
}
