import Vue from 'vue'
import AsyncComputed from 'vue-async-computed'

import {mapActions} from 'vuex'

Vue.use(AsyncComputed, {
  useRawError: true,
  errorHandler (err, vm, info) {
    // We simulate the the faulting component (vm), has mapped the action logClientError
    // and then we invoke it
    let logClientError = mapActions(['logClientError']).logClientError.bind(vm)
    logClientError({err, info})
  }
})
