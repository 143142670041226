import ChildApi from './child-api'

const enc = encodeURIComponent

class RelationsApi extends ChildApi {
  async getRelationSchemas ({cached = true, query} = {}) {
    return this.get({
      url: '/api/v1/relation-schemas',
      query,
      cached
    })
  }

  async getRelation ({relationId, sourceKey, query={}, cached = false}) {
    return this.get({
      url: `/api/v1/relations/${relationId}/${sourceKey}`,
      query,
      cached
    })
  }

  async getReverseRelation ({relationId, targetKey, query={}, cached = false}) {
    return this.get({
      url: `/api/v1/reverse-relations/${relationId}/${enc(targetKey)}`,
      query,
      cached
    })
  }

  async updateRelation({update, query}) {
    return this.post({
      url: `/api/v1/relations/update`,
      body: update,
      query,
    })
  }

  async appendRelation({update, query}) {
    return this.post({
      url: `/api/v1/relations/append`,
      body: update,
      query,
    })
  }

  async unlinkRelation({update, query}) {
    return this.post({
      url: `/api/v1/relations/unlink`,
      body: update,
      query,
    })
  }

  async getreverseRelation ({relationId, sourceKey, query={}, cached = false}) {
    return this.get({
      url: `/api/v1/relations/${relationId}/${enc(sourceKey)}`,
      query,
      cached
    })
  }

}

export default RelationsApi
