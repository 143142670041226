export default {
  state: {
  },
  mutations: {
  },
  getters: {
    translate: (/*state*/) => (defaultText/*, key*/) => defaultText
  },
  actions: {
    async initializeAuthenticatedStore (/*{state, commit, dispatch}*/) {
    }
  }
}
