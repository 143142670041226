import ChildApi from './child-api'

class SuitesApi extends ChildApi {
  async getSuite({suiteId, query={}, cached=false}) {
    return this.get({
      url: `/api/v1/suites/${suiteId}`,
      query,
      cached
    })
  }
  async getSuiteSchema ({suiteId, cached = false, query} = {}) {
    return this.get({
      url: `/api/v1/suite-schemas/${suiteId}`,
      query,
      cached
    })
  }

  async getSuiteSchemas ({cached = true, query} = {}) {
    return this.get({
      url: '/api/v1/suite-schemas',
      query,
      cached
    })
  }

  async updateSuiteSchema({update /*={key, suites}*/, query}) {
    return this.post({
      url: '/api/v1/suite-schemas/update',
      query,
      body: update
    })
  }

  async updateCollectionItem({update /*={key, suites}, query*/}) {
    return this.post({
      url: '/api/v1/suite-collections/update-collection-item',
      body: update
    })
  }
}

export default SuitesApi
