import ChildApi from './child-api'

const enc = encodeURIComponent

class StoredQuriesApi extends ChildApi {
  async getStoredQueries ({cached = false, query} = {}) {
    return this.get({
      url: '/api/v1/stored-queries',
      cached,
      query
    })
  }
  async getStoredQuery (storedQueryId, {cached = false, query} = {}) {
    return this.get({
      url: `/api/v1/stored-query/${enc(storedQueryId)}`,
      cached,
      query
    })
  }
  async updateStoredQuery (storedQuery, {query} = {}) {
    return this.post({
      url: `/api/v1/stored-query/update`,
      body: storedQuery,
      query
    })
  }
  deleteStoredQuery (storedQuery) {
    return this.post({
      url: `/api/v1/stored-query/delete`,
      body: storedQuery
    })
  }
}

export default StoredQuriesApi
