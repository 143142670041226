<template>
  <v-navigation-drawer
    :value="expanded"
    :clipped="$vuetify.breakpoint.lgAndUp"
    app
    dark
    color="primary"
  >
    <v-list dense>
      <template v-for="item in items">
        <v-list-group
          v-if="item.children"
          :key="item.text"
          v-model="item.model"
          :prepend-icon="item.model ? item.icon : item['icon-alt']"
          append-icon=""
          no-action
        >
          <template v-slot:activator>
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item
            v-for="(child, i) in item.children"
            :key="i"
            :to="child.to"
          >
            <v-list-item-action v-if="child.icon">
              <v-icon>{{ child.icon }}</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ child.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-group>
        <v-list-item
          v-else
          :key="item.text"
          :to="item.to"
        >
          <v-list-item-action>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </v-navigation-drawer>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
  props: ['expanded'],
  computed: {
    ...mapGetters(['api', 'collectionsConfig', 'translate']),
    items () {
      const t = this.translate
      return [
        { icon: 'home', text: t('Dashboard'), to: '/' },
        this.collectionItems,
        this.hierarchyItems,
        this.appearanceItems,
        this.settingsItems
      ]
      .filter(v => v)
    },
    collectionItems () {
      let schemas = this.collectionSchemas
      let children = this.collectionsConfig.mapCollectionSchemas(schemas)
        .map(({collectionId, label}) => ({
          icon: 'fa-cube',
          text: label,
          to: `/collection/${collectionId}`,
        }))
      return children.length && {
        icon: 'keyboard_arrow_up',
        'icon-alt': 'keyboard_arrow_down',
        text: this.translate('Collections'),
        model: false,
        children
      }
    },
    hierarchyItems () {
      let schemas = this.collectionSchemas.filter(({key, parentKey}) => key && parentKey)
      let children = this.collectionsConfig.mapCollectionSchemas(schemas)
        .map(({collectionId, label}) => ({
          icon: 'fa-stream',
          text: label,
          to: `/hierarchy/${collectionId}`,
        }))
      return children.length && {
        icon: 'keyboard_arrow_up',
        'icon-alt': 'keyboard_arrow_down',
        text: this.translate('Hierarchies'),
        model: false,
        children
      }
    },
    appearanceItems () {
      const t = this.translate
      return {
        icon: 'keyboard_arrow_up',
        'icon-alt': 'keyboard_arrow_down',
        text: this.translate('Appearance'),
        model: false,
        children: [{
          text: t('Collections'),
          to: '/appearance/collections'
        }, {
          text: t('Relations'),
          to: '/appearance/relations'
        }, {
          text: t('Reverse relations'),
          to: '/appearance/reverseRelations'
        }]
      }
    },
    settingsItems () {
      return {
        icon: 'keyboard_arrow_up',
        'icon-alt': 'keyboard_arrow_down',
        text: this.translate('Settings'),
        model: false,
        children: [{
          icon: 'fa-cube',
          text: this.translate('Channels'),
          to: '/channels'
        }, {
          icon: 'fa-database',
          text: this.translate('Stored queries'),
          to: '/stored-queries'
        }, {
          icon: 'fa-table',
          text: this.translate('Collection schemas'),
          to: '/schemas'
        }, {
          icon: 'fa-table',
          text: this.translate('Suite schemas'),
          to: '/suite-schemas'
        }, {
          icon: 'fa-users',
          text: this.translate('Users'),
          to: '/users'
        }, {
          icon: 'fa-key',
          text: this.translate('API Keys'),
          to: '/apikeys'
        }, {
          icon: 'fa-bug',
          text: this.translate('Client Error Log'),
          to: '/client-error-log'
        }]
      }
    }
  },
  asyncComputed: {
    collectionSchemas: {
      async get () {
        return this.api.collections.getCollectionSchemas()
      },
      default: []
    }
  }
}
</script>
<style>
nav a, nav i {
  color: white!important;
}
</style>
