import ChildApi from './child-api'

class MarketsApi extends ChildApi {
  async getMarkets ({cached = true, query} = {}) {
    return this.get({
      url: '/api/v1/markets',
      cached,
      query
    })
  }
}

export default MarketsApi
