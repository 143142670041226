import CollectionTableBooleanCell  from '@/components/collections/collection-table-boolean-cell'
import CollectionTableLinkCell from '@/components/collections/collection-table-link-cell'
import CollectionTableDataCell from '@/components/collections/collection-table-data-cell'

const CellComponentByAttributeType = {
  image: CollectionTableLinkCell,
  url: CollectionTableLinkCell,
  boolean: CollectionTableBooleanCell,
  '*': CollectionTableDataCell
}

export default {
  state: {
  },
  getters: {
    getAttributeCellComponent: (/*state*/) => ({type} = {}) => CellComponentByAttributeType[type] || CellComponentByAttributeType['*'],
  }
}
