import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

import AdminRoutes from './perspectives/admin/routes'
Vue.use(Router)

let router = new Router({
  routes: [].concat(AdminRoutes)
})

router.beforeEach((to, from, next) => store.dispatch(
  'authenticateRoute', {
    to,
    from,
    next
  }))

router.beforeEach(async (to, from, next) => {
  const injectRoute = to.matched.find(record => record.meta && record.meta.injectParams)
  if (!injectRoute) {
    return next()
  }

  Object.entries(injectRoute.meta.injectParams).forEach(([key, value]) => to.params[key] = value({to, from, next, params: to.params}))
  return next()
})
/*
router.beforeEach((to, from, next) => {
  const beforeRoute = to.matched.find(record => record.meta && record.meta.before)
  if (!beforeRoute) {
    return next()
  }

  beforeRoute.meta.before(to).then(() => next())
})
*/
export default router
