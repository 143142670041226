import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import light from './theme-light'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: {
      // Enable CSS variables
      // see https://vuetifyjs.com/en/customization/theme#custom-properties
      customProperties: true
    },
    themes: { light }
  },
  icons: {
    iconfont: 'fa',
  },
});
