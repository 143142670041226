import axios from 'axios'
import deepEqual from 'fast-deep-equal'
import parseUrl from 'url-parse'

// const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

const isUnauthorizedException = err => (err && err.response && (err.response.status === 403))

export default {
  state: {
    user: null,
    status: ''
  },
  mutations: {
    'SET_AUTH_STATUS': (state, status) => {
      state.status = status
    },
    'SET_AUTH_USER': (state, user) => {
      state.user = user
      if (user === null) {
        state.status = null
      }
    }
  },
  getters: {
    authenticatedUser: state => state.user
  },
  actions: {
    async validateAuthentication ({state, commit, dispatch}) {
      if (state.user && (state.status === 'initialized')) {
        return
      }
      let currentUser = state.user
      let user = await axios.post('/api/v1/authenticate/session/validate', {})
        .then(({data}) => data)
        .catch(error => {
          if (error.response.status === 401) {
            return null
          }
          throw error
        })

      if (deepEqual(currentUser, user)) {
        return
      }
      await commit('SET_AUTH_USER', user)
      if (state.user) {
        return dispatch('initializeAuthenticatedStore')
          .then(() => commit('SET_AUTH_STATUS', 'initialized'))
          .catch(err => {
            if (isUnauthorizedException(err)) {
              return commit('SET_AUTH_STATUS', 'unauthorized')
            }
            throw err
          })
      }
    },

    // High level sign out function suitable as click handler for logout button
    async deauthenticate({commit}) {
      await axios.post('/api/v1/authenticate/session/signout', {})
      await commit('SET_AUTH_USER', null)
    },

    async initializeStore ({state, dispatch}) {
      if (state.auth) {
        return dispatch('initializeAuthenticatedStore')
      }
    },

    initializeAuthenticatedStore(/*{state, commit}*/) {},

    async authenticateRoute({state, dispatch}, {to, /*from,*/ next}) {
      let skipAuthChecks = to.matched.some(record => record.meta && record.meta.skipAuthChecks)
      if (skipAuthChecks) {
        return next()
      }

      // fetch user / update from session/server if missing
      await dispatch('validateAuthentication')

      if (state.status === 'unauthorized') {
        return next({
          name: '401'
        })
      }

      // check if route requires autehticated roles
      const hasRoles = roles => (roles || []).every(role => state.user && state.user.roles.includes(role))
      const allow = to.matched.map(record => record.meta && record.meta.roles)
        .every(hasRoles)

      if (allow) {
        return next()
      }

      let u = parseUrl(window.location, true)
      if (u.query['pp_auth_failed'] === '1') {
        return next({
          name: '401'
        })
      }

      if (state.user) {
        return next({
          name: '401'
        })
      }

      // go to sign in form
      return next({
        name: 'sign-in',
        query: {
          returnUrl: to.fullPath
        }
      })
    }
  }
}
