import ChildApi from './child-api'

class UsersApi extends ChildApi {
  async getUsers ({cached = false, query} = {}) {
    return this.get({
      url: '/api/v1/users',
      cached,
      query
    })
  }
  async createUser (user) {
    return this.post({
      url: `/api/v1/users/create`,
      body: user
    })
  }
  async updateUser (user) {
    return this.post({
      url: `/api/v1/users/update`,
      body: user
    })
  }
  async deleteUser (user) {
    return this.post({
      url: `/api/v1/users/delete`,
      body: user
    })
  }
}

export default UsersApi
