import get from 'object-get'

import {conventionSort, toMap} from './utilities'

export default class RelationsConfig {
  constructor (clientConfiguration) {
    this.config = clientConfiguration
  }

  mapClientSettings (relationSchemas) {
    const g = path => get(this.config, path)
    const normalizeSchema = schema => ({
      relationId: schema.relationId,
      sourceCollectionId: schema.sourceCollectionId,
      targetCollectionId: schema.targetCollectionId,
      label: g(`relations.${schema.relationId}.label`) || null,
      order: g(`relations.${schema.relationId}.order`) || null
    })
    return {
      clientSettingsId: get(this.config, 'clientSettingsId') || '',
      // make collection/schemas a list instead of map
      relations: conventionSort(
        Object.values(relationSchemas)
        .map(schema => normalizeSchema(schema)),
        'collectionId')
    }
  }
  normalizeClientSettings (clientSettings) {
    return {
      ...clientSettings,
      relations: toMap(
        clientSettings.relations,
        ({relationId}) => relationId,
        ({label, order}) => ({
          label,
          order
        }))
    }
  }

  getRelationLabel (relationId) {
    return get(this.config, `relations.${relationId}.label`) || relationId
  }

  mapRelations (relations) {
    const g = path => get(this.config, path)
    let mapped = relations.map(relation => ({
        relationId: relation.relationId,
        relation,
        label: g(`relations.${relation.relationId}.label`) || relation.relationId,
        order: g(`relations.${relation.relationId}.order`) || null
      }))
    return conventionSort(mapped, 'relationId')
  }

  sortRelations (relations) {
    const g = path => get(this.config, path)
    return conventionSort(
      relations.map(relation => ({
        relation,
        relationId: relation.relationId,
        order: g(`relations.${relation.relationId}.order`) || null
      })),
      ({relationId}) => relationId)
      .map(({relation}) => relation)
  }
}
