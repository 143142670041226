import get from 'object-get'

import {conventionSort} from './utilities'

export default class SuitesConfig {
  constructor (config) {
    this.config = config
  }

  getSuiteLabel (suiteId) {
    return get(this.config, `suites.${suiteId}.label`) || suiteId
  }

  getSuiteAttributeLabel (suiteId, {name}) {
    return get(this.config, `suites.${suiteId}.attributes.${name}.label`) || name
  }

  mapSuiteSchema (suiteSchema) {
    const g = path => get(this.config, path)
    const {suiteId, key} = suiteSchema

    return {
      suiteId,
      label: g(`suites.${suiteId}.label`) || suiteId,
      order: g(`suites.${suiteId}.label`) || null,
      attributes: conventionSort(
        Object.values(suiteSchema.attributes || [])
          .map(attribute => ({
            attribute,
            name: attribute.name,
            order: g(`suites.${suiteId}.attributes.${attribute.name}.order`)
          })), 'name')
        .map(({attribute, name}) => ({
          attribute,
          key: attribute.name === key,
          label: g(`suites.${suiteId}.attributes.${name}.label`) || name
        }))
    }
  }


  mapSuitetableAttributes (suiteSchema) {
    const g = path => get(this.config, path)
    const {suiteId} = suiteSchema
    return conventionSort(
      Object.values(suiteSchema.attributes || [])
        .filter(({key}) => !key)
        .filter(({name}) => g(`suites.${suiteId}.attributes.${name}.list.show`) !== false)
        .map(attribute => ({
          attribute,
          name: attribute.name,
          order: g(`suites.${suiteId}.attributes.${attribute.name}.order`)
        })), 'name')
      .map(({attribute, name}) => ({
        attribute,
        label: g(`suites.${suiteId}.attributes.${name}.label`) || name
      }))
  }
}
