export default class PageDescription {
  constructor (collectionsConfig) {
    this.breadcrumbItems = []
    this.collectionsConfig = collectionsConfig
  }

  // called frm layout page
  getBreadcrumbItems () { return this.breadcrumbItems }

  addBreadcrumb ({text, to, exact = true, disabled = false}) {
    this.breadcrumbItems.push({text, to, exact, disabled})
    return this
  }

  addCollection ({collectionId}) {
    return this.addBreadcrumb({
      text: this.collectionsConfig.getCollectionLabel(collectionId),
      to: {
        name: 'collection',
        params: {
          collectionId
        }
      }
    })
  }

  addCollectionItem ({collectionId, itemKey}) {
    return this.addBreadcrumb({
      text: itemKey,
      to: {
        name: 'collection-item',
        params: {
          collectionId,
          itemKey
        }
      }
    })
  }
}
