const keyBy = require('lodash/keyBy')

const defaultMarket = {
  marketId: 'default'
}

export default {
  state: {
    activeMarket: defaultMarket,
    markets: [],
    marketsByMarketId: {}
  },
  mutations: {
    'SET_MARKETS': (state, markets) => {
      let lsMarketId = window.localStorage.getItem('store:markets:marketId') || null
      state.markets = markets
      state.marketsByMarketId = keyBy(markets, 'marketId')
      state.activeMarket =
        state.marketsByMarketId[lsMarketId]
        || state.marketsByMarketId['default']
        || defaultMarket
        /*
        markets.find(({marketId}) => lsMarketId)
        || markets.find(({isDefault}) => isDefault)
        || defaultMarket
        */
    },
    'SET_ACTIVE_MARKET': (state, {marketId}) => {
      let byId = state.marketsByMarketId
      state.activeMarket = byId[marketId] || byId['default']
      window.localStorage.setItem('store:markets:marketId', marketId)
    }
  },
  getters: {
    activeMarket: state => state.activeMarket,
    activeMarketId: state => state.activeMarket.marketId,
    markets: state => state.markets,
    getValidMarket: state => marketId => {
      let byId = state.marketsByMarketId
      return byId[marketId] || state.activeMarket || byId['default'] || defaultMarket
    },
    currentMarketId: state => state.activeMarket.marketId
  },
  actions: {
    initializeStore (/*{state, dispatch}*/) {
    },
    initializeAuthenticatedStore({dispatch}) {
      return dispatch('requestMarkets')
    },
    async requestMarkets({commit, rootGetters: {api: {markets}}}) {
      let m = await markets.getMarkets()
      return commit('SET_MARKETS', m)
    },
    async setActiveMarket({commit}, {marketId}) {
      return commit('SET_ACTIVE_MARKET', {marketId})
    }
  }
}
