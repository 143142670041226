import ChildApi from './child-api'

class ApiKeysApi extends ChildApi {
  async getApiKeyUser (apiKey, {cached = false, query} = {}) {
    return this.get({
      url: `/api/v1/api-key/${encodeURIComponent(apiKey)}`,
      cached,
      query
    })
  }
  async getApiKeyUsers ({cached = false, query} = {}) {
    return this.get({
      url: '/api/v1/api-keys',
      cached,
      query
    })
  }
  async updateApiKeyUser (user, {query} = {}) {
    return this.post({
      url: `/api/v1/api-key/update`,
      body: user,
      query
    })
  }
  deleteApiKeyUser ({apiKey}) {
    return this.post({
      url: `/api/v1/api-key/delete`,
      body: {apiKey}
    })
  }

}

export default ApiKeysApi
