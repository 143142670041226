import ChildApi from './child-api'

class TypesApi extends ChildApi {
  async getTypes ({cached = false, query} = {}) {
    return this.get({
      url: '/api/v1/types',
      cached,
      query
    })
  }
}

export default TypesApi
