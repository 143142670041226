import ChildApi from './child-api'

class ClientSettingsApi extends ChildApi {
  async updateClientSettings({update, query}) {
    return this.post({
      url: `/api/v1/client-settings/update`,
      body: {
        clientSettingsId: 'default',
        ...update
      },
      query
    })
  }
}

export default ClientSettingsApi
