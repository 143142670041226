import ChildApi from './child-api'

const enc = encodeURIComponent

class CollectionsApi extends ChildApi {
  async getCollectionSchemas ({cached = true, query} = {}) {
    return this.get({
      url: '/api/v1/collection-schemas',
      query,
      cached
    })
  }
  async getCollectionSchema ({collectionId, cached = true, query} = {}) {
    return this.get({
      url: `/api/v1/collection-schemas/${enc(collectionId)}`,
      query,
      cached
    })
  }

  async getCollectionItem({collectionId, itemKey, query={}, cached = false}) {
    return this.get({
      url: `/api/v1/collection/${collectionId}/${enc(itemKey)}`,
      query: {...query, itemKey},
      cached
    })
  }

  async getCollection ({collectionId, query={}, cached = false} = {}) {
    return this.get({
      url: `/api/v1/collection/${collectionId}`,
      query,
      cached
    })
  }

  async updateCollectionSchema({update, query}) {
    return this.post({
      url: `/api/v1/collection-schemas/update`,
      body: update,
      query,
    })
  }

  async updateCollection({update, query}) {
    return this.post({
      url: `/api/v1/collection/update`,
      body: update,
      query,
    })
  }

  deleteCollection ({update}) {
    return this.post({
      url: '/api/v1/collection/delete',
      body: update
    })
  }
}

export default CollectionsApi
