export default {
  state: {
    layoutPage: null
  },
  getters: {
    layoutPage: state => state.layoutPage
  },
  actions: {
    async initializeAuthenticatedStore (/*{state, commit}*/) {
    },
    registerLayoutPage ({commit}, {layoutPage, enable}) {
      return commit('NAVIGATION_REGISTER_LAYOUTPAGE', {layoutPage, enable})
    }
  },
  mutations: {
    'NAVIGATION_REGISTER_LAYOUTPAGE'(state, {layoutPage, enable}) {
      if (enable) {
        state.layoutPage = layoutPage
      } else if (state.layoutPage === layoutPage) {
        state.layoutPage = null
      }
    }
  }
}
