<template>
  <span>{{value}}</span>
</template>
<script>
import createPropertyAccessor from '@/lib/create-property-accessor'

export default {
  props: ['header', 'item'],
  data: () => ({accessor: createPropertyAccessor()}),
  computed: {
    value () { return this.accessor(this.item, this.header.value) }
  }
}
</script>
