import get from 'object-get'

import {applyOrder, conventionSort, toMap} from './utilities'

export default class ChannelsConfig {
  constructor (config) {
    this.config = config
  }

  getChannelLabel (channelId) {
    return get(this.config, `channels.${channelId}.label`) || channelId
  }

  mapFormCompleteness (completeness) {
    const g = path => get(this.config, path)
    let mapped = Object.entries(completeness || {})
      .filter(([channelId]) => g(`channels.${channelId}.form.show`) === true)
      .map(([channelId, value]) => ({
        channelId,
        value,
        label: g(`channels.${channelId}.label`) || channelId,
        order: g(`channels.${channelId}.order`) || null,
      }))

    return conventionSort(mapped, 'channelId')
  }

  getCompletenessListHeaders (channels) {
    const g = path => get(this.config, path)
    let mapped = Object.values(channels || {})
      .filter(({channelId}) => g(`channels.${channelId}.list.show`) === true)
      .map(({channelId}) => ({
        channelId,
        label: g(`channels.${channelId}.label`) || channelId,
        order: g(`channels.${channelId}.order`) || null,
      }))

    return conventionSort(mapped, 'channelId')
      .map(({channelId, label}) => ({
        channelId,
        text: label,
        value: `completeness.${channelId}`
      }))
  }
  mapCollectionTableChannels (channels) {
    const g = path => get(this.config, path)
    return conventionSort(
      Object.values(channels || {})
        .filter(({channelId}) => g(`channels.${channelId}.list.show`) === true)
        .map(({channelId}) => ({
          channelId,
          label: g(`channels.${channelId}.label`) || channelId,
          order: g(`channels.${channelId}.order`) || null,
        })), 'channelId')
      .map(({channelId, label}) => ({channelId, label}))
  }

  mapClientSettings (channels) {
    const g = path => get(this.config, path)
    let mapped = Object.values(channels || []).map(channel => ({
      channelId: channel.channelId,
      label: g(`channels.${channel.channelId}.label`) || null,
      order: g(`channels.${channel.channelId}.order`) || null,
      list: {
        show: g(`channels.${channel.channelId}.list.show`) || null
      },
      form: {
        show: g(`channels.${channel.channelId}.form.show`) || null
      }
    }))
    return {
      clientSettingsId: get(this.config, 'clientSettingsId') || '',
      channels: conventionSort(mapped, 'channelId')
    }
  }

  normalizeClientSettings (clientSettings) {
    return {
      ...clientSettings,
      channels: toMap(applyOrder(clientSettings.channels),
        channel => channel.channelId,
        ({label, order, list, form})  => ({label, order, list, form}))
      }
  }


  mapChannels (channels) {
    const g = path => get(this.config, path)
    let mapped = Object.values(channels || []).map(channel => ({
      channelId: channel.channelId,
      channel,
      label: g(`channels.${channel.channelId}.label`) || channel.channelId,
      order: g(`channels.${channel.channelId}.order`),
      list: {
        show: g(`channels.${channel.channelId}.list.show`) !== false
      },
      form: {
        show: g(`channels.${channel.channelId}.form.show`) !== false
      }
    }))
    return conventionSort(mapped, 'channelId')
  }
}
