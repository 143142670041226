class ChildApi {
  constructor (api) {
    this.api = api
  }
  async get(...args) {
    return this.api.get(...args)
  }
  async post(...args) {
    return this.api.post(...args)
  }
}

export default ChildApi
