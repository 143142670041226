import axios from 'axios'

const types = {
  AXIOS_MONITOR_COUNTER_INCREASE: 'AXIOS_MONITOR_COUNTER_INCREASE',
  AXIOS_MONITOR_COUNTER_DECREASE: 'AXIOS_MONITOR_COUNTER_DECREASE'
}
export default {
  state: {
    axiosCounter: 0
  },
  mutations: {
    [types.AXIOS_MONITOR_COUNTER_INCREASE] (state) {
      ++state.axiosCounter
    },
    [types.AXIOS_MONITOR_COUNTER_DECREASE] (state) {
      --state.axiosCounter
    }
  },
  getters: {
    hasPendingAxiosCall: state => state.axiosCounter > 0
  },
  actions: {
    initializeStore ({commit, dispatch}) {
      // Add interceptors to axios that mutates our counter
      axios.interceptors.request.use(async config => {
        await commit(types.AXIOS_MONITOR_COUNTER_INCREASE)
        // store start time of request
        return config
      }, error => {
        commit(types.AXIOS_MONITOR_COUNTER_DECREASE)
        return Promise.reject(error)
      })
      axios.interceptors.response.use((response) => {
        commit(types.AXIOS_MONITOR_COUNTER_DECREASE)
        // console.log(response)
        return response;
      }, (error) => {
        commit(types.AXIOS_MONITOR_COUNTER_DECREASE)

        let {
          message: axiosMessage,
          response: {
            status,
            data: {message: serverMessage}
          } = {data: {}}
        } = error || {}

        // eslint-disable-next-line no-console
        // console.log(JSON.stringify(error, null, 2))
        // Send a notification
        if (status == 404) {
          dispatch('replaceRoute', {name: 'not-found'})
        } else {
          let message = serverMessage || `An error occured while calling PICOPIM backend (${axiosMessage})`
          let notification = {
            message,
            type: 'error'
          }
          
          /*
          let notification = message ? {
            message,
            type: 'error'
          } : {
            message: 'An error occured while calling PICOPIM backend',
            type: 'error'
          }
          */
          commit('NOTIFICATION-MESSAGE', notification)
        }
        return Promise.reject(error)
      })
    }
  }
}
