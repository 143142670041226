const match = {
  // CIDR: /((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))((\/(\d|[12]\d|3[01]))?)){4}$/,
  cidr: v => /((^|\.)((25[0-5])|(2[0-4]\d)|(1\d\d)|([1-9]?\d))((\/(\d|[12]\d|3[01]))?)){4}$/.test(v),
  optional (optional, v) { return (v || '') === '' ? optional : false }
}

class Rules {
  constructor (translate) {
    this._t = translate
  }
  notEmpty () {
    return [
      v => ((v || '').trim().length > 0) || this._t('This value should not be empty', 'validation.fail.empty'),
    ]
  }
  identifier ({optional = false} = {}) {
    return optional
      ? [
        v => /^[A-Za-z0-9_]*$/.test(v) || this._t('Please use web friendly characters (a-z, 0-9 and _)', 'validation.fail.invalidid')
      ]
      : [
        v => ((v || '').trim().length > 0) || this._t('This value should not be empty', 'validation.fail.empty'),
        v => /^[A-Za-z0-9_]+$/.test(v) || this._t('Please use web friendly characters (a-z, 0-9 and _)', 'validation.fail.invalidid')
      ]
  }
  listValue () {
    return [
      v => ((v || '').trim().length > 0) || this._t('This value should not be left blank', 'validation.fail.blank')
    ]
  }
  email () {
    return [
      v => !!v || this._t('E-mail is required'),
      v => /.+@.+\..+/.test(v) || this._t('E-mail must be valid')
    ]
  }
  cidr ({optional = false} = {}) {
    return [
      v => match.optional(optional, v) || match.cidr(v) || this._t('Please specify a valid CIDR specification')
    ]
  }
}

export default {
  state: {
    validationRules: new Rules(),
  },
  mutations: {
  },
  getters: {
    validationRules: (state, rootGetters) => new Rules((...args) => rootGetters.translate(...args))
  },
  actions: {
    initializeStore (/*{state, commit}*/) {
    }
  }
}
