import Vue from 'vue'
import Vuex from 'vuex'
import api from './stores/api'
import attributeTypes from './stores/attribute-types'
import auth from './stores/auth'
import axiosMonitor from './stores/axios-monitor'
import collection from './stores/collection'
import completeness from './stores/completeness'
import config from './stores/config'
import localStorage from './stores/localStorage'
import log from './stores/log'
import markets from './stores/markets'
import navigation from './stores/navigation'
import notifications from './stores/notifications'
import page from './stores/page'
import resources from './stores/resources'
import router from './stores/router'
import schema from './stores/schema'
import stateCache from './stores/state-cache'
import ui from './stores/ui'
import validation from './stores/validation'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    attributeTypes,
    auth,
    axiosMonitor,
    collection,
    completeness,
    config,
    localStorage,
    log,
    markets,
    navigation,
    notifications,
    page,
    resources,
    router,
    schema,
    stateCache,
    ui,
    validation
  },
  state: {

  },
  mutations: {

  },
  actions: {

  }
})
