<template>
  <v-form method="post" :action="endpoint.url">
    <v-text-field name="username" placeholder="email" autocomplete="current-password"/>
    <v-text-field name="password" placeholder="password" type="password" autocomplete="current-password"/>

    <div class="d-flex">
      <v-spacer />
      <v-btn color="primary" type="submit">
        {{translate('Sign in')}}
      </v-btn>
    </div>
  </v-form>
</template>
<script>
import {mapGetters} from 'vuex'
import googleImage from '@/assets/google_signin_buttons/web/1x/btn_google_signin_light_normal_web.png'

export default {
  props: ['endpoint'],
  computed: {
    ...mapGetters(['translate']),
    image() { return googleImage }
  }
}
</script>
