export default {
  state: {
    router: null
  },
  mutations: {
    'SET_ROUTER': (state, router) => {
      state.router = router
    }
  },
  getters: {
  },
  actions: {
    initializeStore (/*{state, dispatch}*/) {
    },
    setRouter({commit}, router) {
      commit('SET_ROUTER', router)
    },
    pushRoute ({state}, route) {
      state.router && state.router.push(route)
    },
    replaceRoute ({state}, route) {
      state.router && state.router.replace(route)
    }
  }
}
