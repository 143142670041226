const emptyHTMLPatterns = [
  /^\s*<p\s*\/>\s*$/ig,
  /^\s*<p\s*>\s*<\/p>\s*$/ig
]

const attributeValidatorsByAttributeType = {
    'html': v => !!v && !emptyHTMLPatterns.some(p => p.test(v)),
    '*': v => !!v
}

const getAttributeValidator = ({type}) => attributeValidatorsByAttributeType[type] || attributeValidatorsByAttributeType['*']

export default {
  state: {
  },
  mutations: {
  },
  getters: {
    completenessRules: (state, rootGetters) => ({/*item,*/ attribute, channels}) => {
      let {name} = attribute
      return Object.values(channels)
        .filter(({attributes}) => attributes[name] > 0)
        .map(({channelId}) => (v => getAttributeValidator(attribute)(v) || `Missing value for channel ${rootGetters.channelsConfig.getChannelLabel(channelId)}`))
    }
  },
  actions: {
    initializeStore (/*{state, commit}*/) {
    }
  }
}
