import axios from 'axios'
import get from 'object-get'

import ChannelsConfig from './channels-config'
import CollectionsConfig from './collections-config'
import RelationsConfig from './relations-config'
import ReverseRelationsConfig from './reverse-relations-config'
import SuitesConfig from './suites-config'

export default {
  state: {
    actions: [],
    clientSettings: {},
    get: () => undefined
  },
  mutations: {
    'CONFIG-SET-ACTIONS': (state, actions) => {
      state.actions = actions
    },
    'SET_CLIENT_SETTINGS': (state, clientSettings) => {
      state.clientSettings = clientSettings
      state.get = (path) => get(clientSettings, path)
    }
  },
  getters: {
    channelsConfig: (state) => new ChannelsConfig(state.clientSettings),
    collectionsConfig: (state) => new CollectionsConfig(state.clientSettings),
    relationsConfig: (state) => new RelationsConfig(state.clientSettings),
    reverseRelationsConfig: (state) => new ReverseRelationsConfig(state.clientSettings),
    suitesConfig: (state) => new SuitesConfig(state.clientSettings),

    translateCollectionName: state => collectionId => state.get(`collections.${collectionId}.name`) || collectionId,
    translateRelationName: state => relationId => state.get(`relations.${relationId}.name`) || relationId,
    translatereverseRelationName: state => relationId => state.get(`relations.${relationId}.backname`) || relationId,
  },
  actions: {
    async initializeAuthenticatedStore ({commit, dispatch}) {
      let {data: clientSettings} = await axios.get('/api/v1/client-settings/default')
      await commit('SET_CLIENT_SETTINGS', clientSettings)
      await dispatch('requestActions')
    },
    requestActions ({commit}) {
      return axios.get('/api/v1/actions')
        .then(({data: actions}) => commit('CONFIG-SET-ACTIONS', actions))
    },

    async invalidateClientConfig ({commit, dispatch}) {
      let {data: clientSettings} = await axios.get('/api/v1/client-settings/default')
      await commit('SET_CLIENT_SETTINGS', clientSettings)
      return dispatch ('clientConfigurationChanged')
    },

    async updateClientConfig ({commit, dispatch}, clientSettings) {
      let {data: newClientSettings} = await axios.post('/api/v1/client-settings/update', {
        clientSettingsId: 'default',
        ...clientSettings
      })
      await commit('SET_CLIENT_SETTINGS', newClientSettings)
      return dispatch ('clientConfigurationChanged')
    }
  }
}
