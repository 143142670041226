import ChildApi from './child-api'

class ActionsApi extends ChildApi {
  async getActions ({cached = false, query} = {}) {
    return this.get({
      url: '/api/v1/actions',
      cached,
      query
    })
  }
  async postFormAction({action: {url}, actionParameters, formData}) {
    return this.post({
      url,
      query: actionParameters,
      body: formData
    })
  }
}

export default ActionsApi
