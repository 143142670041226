<template>
  <v-app-bar
    :clipped-left="$vuetify.breakpoint.lgAndUp"
    app
    color="primary"
    dark
    >
    <!--
    <template v-slot:extension v-if="extended">
      <v-spacer />
      <render-slot
        :slotFunction="extensionSlot" />
    </template>
    -->
    <v-toolbar-title class="ml-0 pl-3" style="min-width: 256px">
      <v-app-bar-nav-icon @click.stop="$emit('input', !value)"></v-app-bar-nav-icon>
      <span class="hidden-sm-and-down">PicoPIM</span>
    </v-toolbar-title>
    <v-toolbar-items>
      <app-market />
    </v-toolbar-items>
    <app-search />
    <v-spacer />
    <v-toolbar-items>
      <render-slot v-if="extended"
        :slotFunction="extensionSlot" />
      <app-user />
    </v-toolbar-items>
    <v-progress-circular v-if="hasPendingAxiosCall" indeterminate/>
  </v-app-bar>
</template>
<script>
import {mapGetters} from 'vuex'
import AppMarket from './app-market.vue'
import AppSearch from './app-search.vue'
import AppUser from './app-user.vue'
import RenderSlot from '@/components/render-slot.vue'
export default {
  props: ['value'],
  components: {
    AppMarket,
    AppSearch,
    AppUser,
    RenderSlot
  },
  computed: {
    ...mapGetters(['layoutPage', 'hasPendingAxiosCall']),
    extended () { return !!this.extensionSlot },
    extensionSlot () {
      return this.layoutPage && this.layoutPage.getToolbarItemsSlot()
    }
  }
}
</script>
