<template>
  <v-menu
    v-model="menu"
    bottom
    absolute
    :close-on-content-click="false">
    <template v-slot:activator="{ on }">
      <v-btn icon large v-on="on">
        <v-avatar size="32px" item>
          <v-img :src="gravatarUrl" />
        </v-avatar>
      </v-btn>
    </template>
    <v-list>
      <v-list-item>
        <v-list-item-avatar>
          <v-img :src="gravatarUrl" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-btn text @click="deauthenticate()">
            <v-icon>exit_to_app</v-icon>
            {{translate('Sign out')}}
          </v-btn>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import gravatar from 'gravatar'
export default {
  data: () => ({
    menu: false
  }),
  computed: {
    ...mapGetters(['authenticatedUser', 'translate']),
    gravatarUrl () {
      return gravatar.url(this.authenticatedUser.email)
    }
  },
  methods: {
    ...mapActions(['deauthenticate'])
  }
}
</script>
