<template>
  <v-menu offset-y v-if="showMarketSelector">
    <template v-slot:activator="{ on }">
      <v-btn text v-on="on">
        <v-icon>fa-globe</v-icon>&nbsp;{{activeMarket.description}}
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(market, index) in markets"
        :key="index"
        @click="setActiveMarket(market)"
        >
        <v-list-item-title>{{ market.description }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
export default {
  computed: {
    ...mapGetters(['activeMarket', 'markets']),
    showMarketSelector () {
      return this.activeMarket && this.markets && (this.markets.length > 1)
    }
  },
  methods: {
    ...mapActions(['setActiveMarket'])
  }
}
</script>
