const {property} = require('lodash')

function createPropertyAccessor () {
  let _path = null
  let _property = null
  return function get(object, path, defaultValue) {
    if (!(_property && (_path == path))) {
      _path = path
      _property = property(path)
    }
    let v = _property(object)
    return v === undefined ? defaultValue : v
  }
}

export default createPropertyAccessor
