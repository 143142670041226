import ChildApi from './child-api'

class RolesApi extends ChildApi {
  async getRoles ({cached = true, query} = {}) {
    return this.get({
      url: '/api/v1/roles',
      cached,
      query
    })
  }
}

export default RolesApi
