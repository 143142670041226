/**
  * Dont use localStorage sine cached models might be out of sync
  * between sessions, most notably table state
  *
  */
//const ls = window && window.localStorage
const ls = createFakeLocalStorage()

const serialize = value => JSON.stringify(value)
const deserialize = json => (typeof json === 'string') && JSON.parse(json)

const getItem = key => deserialize(ls.getItem(key))
const setItem = (key, value) => (typeof value === 'undefined')
  ? ls.removeItem(key)
  : ls.setItem(key, serialize(value))

function createFakeLocalStorage () {
  let storage = {}
  return {
    getItem: key => storage[key],
    setItem: (key, value) => { storage[key] = value },
    removeItem: key => { delete storage[key] }
  }
}

class NamedEntry {
  constructor (key) {
    this.key = key
  }
  get () { return getItem(this.key) }
  set (value) { return setItem(this.key, value) }
  child (childName) {
    return new NamedEntry(`${this.key}/${childName}`)
  }
}
export default {
  state: {
  },
  getters: {
    stateCache: state => state.localStorageWrapper,
    getStateCache: (/*state*/) => key => new NamedEntry(key)
  }
}
