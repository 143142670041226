<template>
  <span v-html="header.template(value)"></span>
</template>
<script>
import get from 'object-get'
export default {
  props: ['header', 'item'],
  computed: {
    value () { return get(this.item, this.header.value) }
  }
}
</script>
