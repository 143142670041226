<template>
  <component :is="applicationComponent" />
  <!--
  <v-app>
    <router-view/>
  </v-app>
  -->
</template>
<script>
import {mapGetters, mapActions} from 'vuex'

import unauthorizedApp from '@/perspectives/unauthorized/app.vue'
import adminApp from '@/perspectives/admin/app.vue'

export default {
  created () {
    this.setRouter(this.$router)
    this.initializeStore()
  },
  computed: {
    ...mapGetters(['authenticatedUser']),
    applicationComponent () {
      return this.authenticatedUser ? adminApp : unauthorizedApp
    }
  },
  methods: {
    ...mapActions(['setRouter', 'initializeStore'])
  }
}
</script>
