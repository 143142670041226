<template>
  <div
    style="position:relative"
    class="hidden-sm-and-down"
    :class="{'show-search-results': showSearchResults}"
    v-if="searchAction">
    <v-text-field
      v-model="search"
      flat
      hide-details
      prepend-inner-icon="search"
      :placeholder="searchAction.title || translate('Search...')"
      class="hidden-sm-and-down"
      @focus="showSearchResults = true"
      @blur="showSearchResults = false"
    >
    </v-text-field>
    <v-list
      v-if="searchResult.length"
      style="position: absolute; max-height: 50vh; overflow-y: auto"
      color="primary"
      class="search-results"
      >
      <v-list-item v-for="{title, subtitle, image, link} in searchResult" :to="link" :key="link">
        <v-list-item-content>
          <v-list-item-title>{{title}}</v-list-item-title>
          <v-list-item-subtitle>{{subtitle}}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-avatar>
          <img :src="image" v-if="image">
        </v-list-item-avatar>
      </v-list-item>
    </v-list>
  </div>
</template>
<script>
import axios from 'axios'
import {mapGetters} from 'vuex'
import debounce from 'lodash/debounce'

const cacheKey = 'perspective:admin:appbar:search'
export default {
  data: () => ({
    search: '',
    searchResult: [],
    lastSearch: null,
    pending: null,
    showSearchResults: false
  }),
  computed: {
    ...mapGetters(['api', 'translate', 'localStorage'])
  },
  asyncComputed: {
    searchAction: {
      async get () {
        let allActions = await this.api.actions.getActions({cached: true})
        return allActions.find(({type}) => type === 'full-text-search')
      },
      default: null
    }
  },
  watch: {
    search: debounce(function () {
      this.enqueueSearch()
    }, 500),
    searchAction () {
      this.search = this.search || this.localStorage.getItem(cacheKey) || ''
    }
  },
  methods: {
    enqueueSearch () {
      let { search, lastSearch, pending } = this
      this.localStorage.setItem(cacheKey, search || '')
      if (pending) {
        return
      }
      if (search === lastSearch) {
        return
      }
      if (search.length < 3) {
        return
      }
      this.lastSearch = search
      this.pending = axios
        .get(this.searchAction.url, {
          params: {
            marketId: 'default', query: search
          }
        })
        .then(({data}) => this.searchResult = data)
        .finally(() => {
          this.pending = null
          this.$nextTick(() => this.enqueueSearch())
        })
    }
  }
}
</script>
<style scoped>
.search-results {
  display: none;
}
.show-search-results .search-results {
  display: block;
}
.search-results:hover {
  display: block;
}
</style>
