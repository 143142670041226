import ChildApi from './child-api'

class RecipesApi extends ChildApi {
  async complementCollectionItems({update, query}) {
    return this.post({
      url: `/api/v1/recipes/complement-collection-items`,
      body: update,
      query,
    })
  }
}

export default RecipesApi
