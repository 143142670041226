// src/plugins/vuetify/theme.js

// import colors from 'vuetify/lib/util/colors'

export default {
  primary: '#061F5B',
  secondary: '#982B7A',
  warning: '#982B7A'
}

/*
export default {
  primary: {
    base: colors.blue.base
    // darken1: colors.purple.darken2,
  },
  secondary: colors.blue.lighten4,
  // All keys will generate theme styles,
  // Here we add a custom `tertiary` color
  tertiary: colors.pink.base,
  accent: colors.amber,
  error: colors.red.accent3
}
*/
