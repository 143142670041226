import Vue from 'vue'
import axios from 'axios'
import get from 'object-get'
// import querystring from 'querystring'
import parseUrl from 'url-parse'

import ActionsApi from './actions-api'
import ApiKeysApi from './api-keys-api'
import AuthenticationApi from './authentication-api'
import ChannelsApi from './channels-api'
import ClientSettingsApi from './client-settings-api'
import CollectionsApi from './collections-api'
import MarketsApi from './markets-api'
import RecipesApi from './recipes-api'
import RelationsApi from './relations-api'
import RolesApi from './roles-api'
import StoredQuriesApi from './stored-queries-api'
import SuitesApi from './suites-api'
import TypesApi from './types-api'
import UsersApi from './users-api'

const wait = ms => new Promise(resolve => setTimeout(resolve, ms))

const appendQuery = (url, query) => {
  let parsed = parseUrl(url, true)
  parsed.query = {
    ...parsed.query,
    ...query
  }
  return parsed.toString()
}

class Api {
  constructor () {
    this.cache = {}
  }
  get actions () { return new ActionsApi(this) }
  get apiKeys () { return new ApiKeysApi(this) }
  get authentication () { return new AuthenticationApi(this) }
  get channels () { return new ChannelsApi(this) }
  get clientSettings () { return new ClientSettingsApi(this) }
  get collections () { return new CollectionsApi(this) }
  get markets () { return new MarketsApi(this) }
  get recipes () { return new RecipesApi(this) }
  get relations () { return new RelationsApi(this) }
  get roles () { return new RolesApi(this) }
  get storedQueries () { return new StoredQuriesApi(this) }
  get suites () { return new SuitesApi(this) }
  get types () { return new TypesApi(this) }
  get users () { return new UsersApi(this) }



  async getStats ({cached = false, query} = {}) {
    return this.get({
      url: '/api/v1/stats',
      query,
      cached
    })
  }

  async get ({cached, url, query = {}, path = '', delay}) {
    // let call = () => axios.get(`${url}?${querystring.stringify(query)}`)
    let call = () => axios.get(appendQuery(url, query))
    delay && (await wait(delay))
    return (cached ? this.ensureCache(`GET ${url}`, call) : call())
      .then(({data}) => path ? get(data, path) : data)
      .then(mapped => Vue.observable(mapped))
  }
  async post ({url, body, query, headers = {}}) {
    // return axios.post(`${url}?${querystring.stringify(query)}`, body)
    return axios
      .post(appendQuery(url, query), body, {
        headers: headers
      })
      .then(({data}) => data)
  }

  ensureCache (key, factory) {
    if (!this.cache.hasOwnProperty(key)) {
      this.cache[key] = factory()
    }
    return this.cache[key]
  }
}

export default {
  state: {
    api: new Api()
  },
  mutations: {
  },
  getters: {
    api: (state/*, rootState*/) => {
      // console.log({state, rootState})
      return state.api
    }
  },
  actions: {
  }
}
